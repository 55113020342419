import './RegisterPage.scss';
import React from 'react'
import { useState, useEffect } from 'react';
import { useMutation } from 'react-query';
import { UserService } from '../../services/users/UserService';
import { UserInterface } from '../../services/users/UserInterface';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { Col, Image, Form, Input, Button, Typography, Layout } from 'antd';
import Navbar from '../../components/Navbar/Navbar';
import Footer from '../../components/Footer/Footer';
import { AxiosError, AxiosResponse } from 'axios';

const RegisterPage : React.FC = () => {
    const { t } = useTranslation();
    const {Title} = Typography;
    const {Content} = Layout;

    const registerForm : UserInterface = {
        username: "",
        password: "",
        email: ""
    };

    const [registerData , setRegisterData] = useState<UserInterface>(registerForm);
    const [errorMessage, setErrorMessage] = useState('')
    const mutation = useMutation((registerData : UserInterface) => UserService.registerUser(registerData));

    const navigate = useNavigate()
    
    const handleChange = (e: any) => {
        const {name, value} = e.target;
        setRegisterData(oldData => ({
            ...oldData,
            [name]: value
        }));
    }

    const handleSubmit = async (e: any) => {
        mutation.mutate(registerData,
            {
                onSuccess: () => {
                    navigate('/verificationmessage');
                },
                onError: (error) => {
                    const errorStatusCode = (error as AxiosError).response?.status;
                    if (errorStatusCode === 403) {
                        setErrorMessage(t('RegisterPage.mailError'))
                    }
                }
            }
        );
    }

    return(
        <Layout className='registerContainer'>
            <Navbar/>
            <Content>
                <div className='registerBG'>
                    <svg width="1440" height="435" viewBox="0 0 1440 435" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1440 429C1440 437.5 1442.46 433.5 708.963 433.5C-47.0366 433.5 0.963318 433.5 -1.53668 433.5C-1.53668 218.937 323.109 0.5 722.963 0.5C1122.82 0.5 1440 214.437 1440 429Z" fill="#75E3EA"/>
                    </svg>
                </div>
                <Col xs ={15} sm={15} md={10} lg={10} xl={7} className="registerForm">    
                    <Title className='formTitle'>{t('RegisterPage.title')}</Title>
                    <Title level={2} className='formSubtitle'>{t('RegisterPage.subtitle')}</Title>
                    <Form onFinish={handleSubmit} layout='vertical' className='form'>
                        <Form.Item name='username' className='formGroup'>
                            <Input name='username' className='formInput' onChange={handleChange} placeholder={t('RegisterPage.userName')} required></Input>
                        </Form.Item>
                        <Form.Item name='email' className='formGroup'>
                            <Input name='email' className='formInput' onChange={handleChange} placeholder={t('RegisterPage.email')} required></Input>
                        </Form.Item>
                        <Form.Item name='password' className='formGroup'>
                            <Input.Password name='password' className='formInput' onChange={handleChange} placeholder={t('RegisterPage.password')} required></Input.Password>
                        </Form.Item>
                        
                        {mutation.isLoading ? <p>{t('RegisterPage.sending')}</p> : null}
                        {mutation.isSuccess ? <p>{t('RegisterPage.correct')}</p> : null}
                        {mutation.error instanceof AxiosError ? <p>{t('RegisterPage.error')}. <br/>{errorMessage}</p> : null}
                        <Form.Item>
                            <Button className='btn btnLarge secondBtnDark signupBtn' type='primary' htmlType='submit'>{t('RegisterPage.signUp')}</Button>
                            <p className='btnText'>{t('RegisterPage.alreadyHave')} <Link to={'/login'} className='signinLink'>{t('RegisterPage.signIn')}</Link></p>
                        </Form.Item>
                    </Form>
                </Col>
            </Content>
            <Footer/>
        </Layout>
    );
}

export default RegisterPage;