import Map from './Map';
import { useLoadScript } from '@react-google-maps/api'
import { useState } from 'react';

type GoogleMapsLibraries = ("places" | "drawing" | "geometry" | "localContext" | "visualization")[]

const Maps = (props : any) => {
    const { handleMap, placeholder } = props

    const mapKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY
    const [libraries] = useState<GoogleMapsLibraries>(['places'])
    const { isLoaded } = useLoadScript({
        googleMapsApiKey: mapKey!,
        libraries,
    });

    if (!isLoaded) { 
        return <div>Loading...</div>}

    return (
        <Map handleMap={handleMap} placeholder={placeholder}/>
    )
}

export default Maps