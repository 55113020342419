import { API_URL } from "../../configs";
import { ApiCaller } from "../ApiCaller";
import { FilterInterface } from "./FilterInterface";
import queryString from 'query-string'

export class LostDogService {
  public static getLostDogUrl(): string {
    return `${API_URL}/api/lost-dog`;
  }

  public static async publishLost (lostDogData: FormData) {
    return ApiCaller.post(LostDogService.getLostDogUrl(), lostDogData, {withCredentials: true});
  }

  public static async deletePost (id : number) {
    return ApiCaller.delete(`${LostDogService.getLostDogUrl()}/${id}`, {withCredentials: true})
  }

  public static async editPost (id : number, updatedProps : any) {
    return ApiCaller.patch(`${LostDogService.getLostDogUrl()}/${id}`, updatedProps, {withCredentials: true})
  }

  public static async getMyLostDogPosts () { 
    const response = await ApiCaller.get(`${LostDogService.getLostDogUrl()}/get-my-posts`, { withCredentials: true });
    return response.data;

  }

  public static async getLostDogsPosts () {
    const response = await ApiCaller.get(`${LostDogService.getLostDogUrl()}/get-lost-dogs`);
    return response.data;

  }

  public static async getLostDogPost (id : string) {
    const response = await ApiCaller.get(`${LostDogService.getLostDogUrl()}/get-lost-dog-by-id/${id}`)
    return response.data;
  }

  public static async getSearchedDogsPosts(take:number, skip:number, filterParams? : FilterInterface){
    const getParams = filterParams || {};
    getParams.take = take;
    getParams.skip = skip;
    const filterQueryString = queryString.stringify(getParams);
    return ApiCaller.get(`${LostDogService.getLostDogUrl()}/get-lost-dogs/search?${filterQueryString}`)
  }

  public static async getDogBreeds() {
    const response = await ApiCaller.get(`${LostDogService.getLostDogUrl()}/breeds`);
    return response.data;
  }
}