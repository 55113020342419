import { API_URL } from "../../configs";
import { UserInterface } from "./UserInterface";
import { ApiCaller } from "../ApiCaller";

export class UserService {
  public static getRegisterUrl(): string {
    return `${API_URL}/api/users`;
  }

  public static async registerUser (userData: UserInterface) { 
    return ApiCaller.post(UserService.getRegisterUrl(), userData);
  }

  public static async getUserByEmail(email : string){
    const response = await ApiCaller.get(`${UserService.getRegisterUrl()}/get-user-by-email/${email}`);
    return response?.data;
  }

  public static async getUsername(email : string){
    const userInfo = (await this.getUserByEmail(email)).username
    return userInfo;
  }
}