import dog1 from './dog1.png';
import dog2 from './dog2.png';
import dog3 from './dog3.png';
import dog4 from './dog4.png';
import dog5 from './dog5.png';

const dogAvatars = {
    'dog1': dog1,
    'dog2': dog2,
    'dog3': dog3,
    'dog4': dog4,
    'dog5': dog5,
}

export default dogAvatars