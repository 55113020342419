import './Navbar.scss'
import { Layout, Menu, Image, Button } from 'antd';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { SessionService } from '../../services/users/SessionService';

const Navbar = () => {
    const { t } = useTranslation();
    const { Header } = Layout;
    const [isLogged, setIsLogged] = useState<Boolean>(false)

    useEffect(() => {
        if (localStorage.getItem('user')) {
            setIsLogged(true)
        }
    }, [localStorage.getItem('user')]);

    const logout = async () => {
        localStorage.removeItem('user');
        localStorage.removeItem('email');
        await SessionService.logout()
        setIsLogged(false)
    }

    const navItems = [
        {
            label: <Link to='/'>{t('Navbar.home')}</Link>,
            key: 'home',
            className: 'navItem mediumLabel'
        },
        {
            label: <Link to='/lostDogs'>{t('Navbar.lostDogs')}</Link>,
            key: 'lostDogs',
            className: 'navItem mediumLabel'
        },
    ]

    const loggedItems = [
        {
            label: `${t('Navbar.myAccount')}`,
            key: 'account',
            className: 'navItem',
            children: [
                {
                    label: <Link to='/profile'>{t('Navbar.profile')}</Link>,
                    key: 'profile',
                    className: 'navSubItem',
                },
                {
                    label: <Link to='/publishlost'>{t('Navbar.publish')}</Link>,
                    key: 'publish',
                    className: 'navSubItem',
                },
                {
                    label: <Link to='/' onClick={logout}>{t('Navbar.logout')}</Link>,
                    key: 'logout',
                    className: 'navSubItem'
                },
            ]
        }
    ]

    const rightNav = (isLogged: Boolean) => {
        if (!isLogged) {
            return <div className="btnContainer">
                        <Button className='navBtn btn btnSmall primaryBtnLight'>
                            <Link to='/login'>
                                {t('Navbar.login')}
                            </Link>
                        </Button>
                        <Button className='navBtn btn btnSmall primaryBtnDark'>
                            <Link to='/register'>
                                {t('Navbar.register')}
                            </Link>
                        </Button>
                    </div>
        } else {
            return <Menu
                        className='navbarMenu'
                        mode="horizontal"
                        defaultSelectedKeys={['home']}
                        items={loggedItems}
                    />
        }
    }

    return (
        <Header className='navbar'>
            <div className="leftNav">
                <Link to="/"><Image src="../logo.png" alt="logo" preview={false} className="navbarLogo" /></Link>
                <Menu
                    className='navbarMenu'
                    mode="horizontal"
                    defaultSelectedKeys={['home']}
                    items={navItems}
                />
            </div>
            <div className="rightNav">
                {rightNav(isLogged)}
            </div>
        </Header>
    )
}

export default Navbar