import './CredentialVerificationPage.scss'
import { Row, Col, Typography, Button, Input, message } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { VerificationService } from '../../services/users/VerificationService';

const CredentialVerification : React.FC = () => {
    const navigate = useNavigate()
    const { Title, Paragraph } = Typography;
    const { t } = useTranslation();

    const [verificationCode, setVerificationCode] = useState<string>('');

    const handleClick = (event : any) => {
        setVerificationCode(event.target.value);
    }

    const handleSubmit = async () => {
        const isValidCode = await VerificationService.codeVerification(verificationCode)
        if (isValidCode){
            navigate('/profile');
        } else {
            message.error(<div>{t('VerificationPage.error')}</div>);
        }
    }
    return(
        <Row className="verificationRow" justify='center' >
            <Col span={8} className='verificationContainer'>  
                <div className='verificationText'>
                    <Title className='text' level={3}>{t('VerificationPage.title')}</Title>
                    <Paragraph className='text'>{t('VerificationPage.subtitle')}</Paragraph>
                </div>
                <Input.Group className='verificationGroup'>
                    <Input className='verificationInput' placeholder='123456789' onChange={handleClick}/>
                    <Button type="primary" onClick={handleSubmit}>{t('VerificationPage.send')}</Button>
                </Input.Group>
            </Col>
        </Row>
    )
}
export default CredentialVerification
