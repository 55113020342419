import './LostDogPostPage.scss'
import { useTranslation } from 'react-i18next';
import { Row, Col, Typography, Image, Button, Layout } from 'antd';
import { MailOutlined, PhoneOutlined, DownloadOutlined, PrinterOutlined, WhatsAppOutlined, CloseSquareOutlined } from '@ant-design/icons'
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { LostDogInterface } from '../../services/lostDogs/LostDogInterface';
import { LostDogService } from '../../services/lostDogs/LostDogService';
import DogPoster from '../../components/Poster/DogPoster';
import PosterPDF from '../../components/Poster/PosterPDF';
import { PDFDownloadLink, PDFViewer } from '@react-pdf/renderer';
import Navbar from '../../components/Navbar/Navbar';
import Footer from '../../components/Footer/Footer';
import { Content } from 'antd/lib/layout/layout';

const LostDogPostPage: React.FC = () => {
    const { Title, Paragraph } = Typography;
    const { t } = useTranslation();
    const { id } = useParams();
    const currentDate = new Date().toLocaleDateString()

    const lostDogPost : LostDogInterface= {
        dogName: '',
        breed: '',
        gender: '',
        date: '',
        owner: {
            username: '',
            email: '',
        },
        phoneNumber: '',
        email: '',
        description: '',
        dogSize: '',
        address: '',
        latitude: '',
        longitude: '',
        images: [''],
        id: 0,
    };
    const [lostDogData, setLostDogData] = useState<LostDogInterface>(lostDogPost);
    const [loading, setLoading] = useState(false);
    const [viewPDF, setViewPDF] = useState(false)

    useEffect(() => {
        if (!id) {
            return;
        }
        const loadPost = async () => {
            setLoading(true);
            const post = await LostDogService.getLostDogPost(id);
            setLostDogData(post)
            setLoading(false);
        };
        loadPost();
    }, [])

    if(viewPDF){
        window.addEventListener('keyup', function(e : KeyboardEvent ){
            e.preventDefault() 
            if (e.key === 'Escape') {
              setViewPDF(false)
            }
        });
    }
    return (
        <Layout className="dogPostContainer">
            <Navbar/>
            <Content>
                <Row justify={'space-between'}>
                    <Col span={9} className='posterContainer flexCenter'>
                        {/* <Title level={3} className='posterTitle'>{t('LostDogPostPage.poster')}</Title> */}
                        <div className='dogPosterContainer'>
                            <Paragraph className="posterText">{t('LostDogPostPage.posterText')}</Paragraph>
                            <div className='dogPosterImg'>
                                <DogPoster key={lostDogData.id} dogName={lostDogData.dogName} breed={lostDogData.breed} images={lostDogData.images!} description={lostDogData.description} date={lostDogData.date} address={lostDogData.address} ownersName={lostDogData.owner.username} phoneNumber={lostDogData.phoneNumber} id={lostDogData.id}/>
                            </div>
                            <div className="posterData">
                                {/* <Paragraph className="posterText">{t('LostDogPostPage.posterText')}</Paragraph>
                                <Paragraph className="posterText">{t('LostDogPostPage.posterText2')}</Paragraph> */}
                                <div className="posterBtn">
                                    <PDFDownloadLink
                                        document={<PosterPDF key={lostDogData.id} dogName={lostDogData.dogName} breed={lostDogData.breed} images={lostDogData.images!} description={lostDogData.description} date={lostDogData.date} address={lostDogData.address} ownersName={lostDogData.owner.username} phoneNumber={lostDogData.phoneNumber} id={lostDogData.id}/>}
                                        fileName={`${t('LostDogPostPage.fileName')}${currentDate}`}>
                                        {({ loading  }) => (loading ? `${t('LostDogPostPage.loading')}` : <DownloadOutlined className='downloadIcon' />)}
                                    </PDFDownloadLink>
                                    <a type='link' onClick={()=>{setViewPDF(!viewPDF)}}>
                                        <PrinterOutlined className='printerIcon'/>
                                    </a>
                                </div>
                                {viewPDF ?
                                    <div className='PDFContainer'>
                                        <Button type='link' onClick={()=>{setViewPDF(false)}} className='closePDFBtn'>
                                            <CloseSquareOutlined className='closePDFIcon'/>
                                        </Button>
                                        <PDFViewer className='pdfViewer'>
                                            <PosterPDF key={lostDogData.id} dogName={lostDogData.dogName} breed={lostDogData.breed} images={lostDogData.images!} description={lostDogData.description} date={lostDogData.date} address={lostDogData.address} ownersName={lostDogData.owner.username} phoneNumber={lostDogData.phoneNumber} id={lostDogData.id}/>
                                        </PDFViewer>
                                    </div>
                                : null}
                            </div>
                            <Paragraph className="posterText">{t('LostDogPostPage.posterText2')}</Paragraph>
                        </div>
                    </Col>
                    <Col span={15} className='dogDataContainer'>
                        <div className='lostDogBG'>
                            <svg width="895" height="881" viewBox="0 0 895 881" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M213 0.951904C83.2433 103.706 0 262.632 0 441C0 619.341 83.2173 778.245 212.939 881H895V0.951904H213Z" fill="url(#paint0_linear_216_202)"/>
                                <defs>
                                <linearGradient id="paint0_linear_216_202" x1="464.592" y1="0.951896" x2="260.998" y2="831.067" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#D9E8EF"/>
                                <stop offset="1" stop-color="#EAF2F5"/>
                                </linearGradient>
                                </defs>
                            </svg>
                        </div>
                        <div className="dogData">
                            <Title className='dogName'>{lostDogData.dogName}</Title>
                            <Row className='pictures'>
                                {loading ? (<p>{t('LostDogPostPage.loading')}</p>) : (lostDogData.images?.map( (element : string, index : number) =>
                                    <div className='dogImgContainer' key={index} >
                                        <Image src={element} alt='dog picture' className='dogImg' />
                                    </div>
                                ))}
                            </Row>
                            <Row className='dataRow'>
                                <Row className='dogInformation'>
                                    <Col span={12} className="infoData">
                                        <Paragraph><span className='dataLabel'>{t('LostDogPostPage.breed')}</span><span className='dataText'>{lostDogData.breed}</span></Paragraph>
                                        <Paragraph><span className='dataLabel'>{t('LostDogPostPage.sex')}</span><span className='dataText'>{t(`LostDogPostPage.Gender.${lostDogData.gender}`)}</span></Paragraph>
                                        <Paragraph><span className='dataLabel'>{t('LostDogPostPage.dogSize')}</span><span className='dataText'>{t(`LostDogPostPage.DogSize.${lostDogData.dogSize}`)}</span></Paragraph>
                                    </Col>
                                    <Col span={12} className="infoData">
                                        <Paragraph><span className='dataLabel'>{t('LostDogPostPage.contact')}</span><span className='dataText'>{lostDogData.owner.username}</span></Paragraph>
                                        <Paragraph><span className='dataLabel'>{t('LostDogPostPage.phone')}</span><span className='dataText'>{lostDogData.phoneNumber}</span></Paragraph>
                                        <Paragraph><span className='dataLabel'>{t('LostDogPostPage.email')}</span><span className='dataText'>{lostDogData.owner.email}</span></Paragraph>
                                    </Col>
                                </Row>
                                <Row className="dogDescription">
                                    <Paragraph><span className='dataLabel'>{t('LostDogPostPage.description')}</span><span className='dataText'>{lostDogData.description}</span></Paragraph>
                                </Row>
                            </Row>
                            <div className="contact">
                                <Paragraph className='contactTitle'>{t('LostDogPostPage.contactOwner')}</Paragraph>
                                <div className="iconContainer">
                                    <a href={'tel:' + lostDogData.phoneNumber} target={'_blank'} rel="noreferrer"><PhoneOutlined className='contactIcon' /></a>
                                    <a href={'https://api.whatsapp.com/send?phone=' + lostDogData.phoneNumber} target={'_blank'} rel="noreferrer"><WhatsAppOutlined className='contactIcon' /></a>
                                    <a href={'mailto:' + lostDogData.owner.email} target={'_blank'} rel="noreferrer"><MailOutlined className='contactIcon' /></a>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Content>
            <Footer/>
        </Layout>
    );
}

export default LostDogPostPage