import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query'
import "./App.scss";
import RegisterPage from './pages/register-page/RegisterPage';
import LoginPage from './pages/login-page/LoginPage';
import PublishLostPage from './pages/publish-lost-page/PublishLostPage';
import ProfilePage from './pages/profile-page/ProfilePage';
import LostDogPostPage from './pages/lost-dog-post-page/LostDogPostPage';
import LostDogsPage from './pages/lost-dogs-page/LostDogsPage';
import CredentialVerification from './pages/credential-verefication-page/CredentialVerificationPage';
import VerificationMessage from './pages/verification-message-page/VerificationMessagePage';
import ConfirmEmail from './pages/confirm-email/ConfirmEmail';
import HomePage from './pages/home-page/HomePage';

const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <div className="App">
        <Router>
          <Routes>
            <Route path="/" element={<HomePage/>} />
            <Route path="/register" element={<RegisterPage/>} />
            <Route path="/login" element={<LoginPage/>} />
            <Route path="/verification" element={<CredentialVerification/>} />
            <Route path="/verificationmessage" element={<VerificationMessage/>} />
            <Route path="/publishlost" element={<PublishLostPage/>} />
            <Route path="/profile" element={<ProfilePage/>} />
            <Route path="/lostDog/:id" element={<LostDogPostPage/>}/>
            <Route path="/lostDogs" element={<LostDogsPage/>}/>
            <Route path="/confirm-email/" element={<ConfirmEmail/>}/>
          </Routes>
        </Router>
      </div>
    </QueryClientProvider>
  );
}

export default App;
