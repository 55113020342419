import { API_URL } from "../../configs";
import { SessionInterface } from "./SessionInterface";
import { ApiCaller } from "../ApiCaller";

export class SessionService {
  
  public static getLoginUrl(): string {
    return `${API_URL}/api/auth/login`;
  }

  public static async loginUser (userData: SessionInterface) {
    return ApiCaller.post(SessionService.getLoginUrl(), userData, {withCredentials: true}); 
  }

  public static async logout (){
    const response = await ApiCaller.get( `${API_URL}/api/auth/logout`, {withCredentials: true});
    return response.data
  }
}