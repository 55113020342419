import './Message.scss'
import {  Col, Typography} from 'antd';

interface MessageInterface{
    title: string;
    subtitle: string;
}

function Message (props : MessageInterface) {  
    const { Title, Paragraph } = Typography;
    const { title , subtitle } = props;

    return (
        <Col span={8} className='messageContainer'>  
            <div className='messageText'>
                <Title className='text' level={3}>{ title }</Title>
                <Paragraph className='text'>{ subtitle }</Paragraph>
            </div>
        </Col>
    )
}

export default Message