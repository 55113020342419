import './VerificationMessagePage.scss'
import { Row } from 'antd';
import { useTranslation } from 'react-i18next';
import Message from '../../components/Message';

const VerificationMessage : React.FC = () => {
    const { t } = useTranslation();

    return(
        <Row className="verificationRow" justify='center' >
            <Message title={t('VerificationMessagePage.title')} subtitle={t('VerificationMessagePage.subtitle')} />
        </Row>
    )
}
export default VerificationMessage
