import { API_URL } from "../../configs";
import { ApiCaller } from "../ApiCaller";
import { UserService } from "./UserService";

export class VerificationService {
    public static getAuthUrl(): string {
        return `${API_URL}/api/auth`;
      }

    public static codeVerification(verificationCode:string) {
        const testCode = 'abc123';
        if (verificationCode === testCode){
            return true;
        } else {
            return false;
        };
    };

    public static async emailConfirmation(token : string){
        return ApiCaller.patch(`${VerificationService.getAuthUrl()}/email/confirm`, { token });
    }

    public static async resendMail(email : string){ 
        const user = await (await UserService.getUserByEmail(email))
        return ApiCaller.post(`${VerificationService.getAuthUrl()}/email/resend-confirmation-link`, user);
    }
}