import { LostPostInterface } from '../../services/lostDogs/LostPostInterface'
import { useTranslation } from 'react-i18next';
import { Document, Page, View, Text, StyleSheet, Image } from '@react-pdf/renderer';
import { dateFormat } from '../../utils/util';
import Images from '../../img/images'

const PosterPDF = (props : LostPostInterface) => {
  const { t } = useTranslation();
  const { dogName, description, images, breed, date, address, id, ownersName, phoneNumber} = props;
  const currentDate = new Date().toLocaleString()

  const styles = StyleSheet.create({
    poster: {
        width: '463px',
        height: '652px',
        padding: '20px',
        marginTop: '30pt',
        marginHorizontal: 'auto',
        textAlign: 'center',
    },
    pageBackground: {
        position: 'absolute',
        width: '463px',
        height: '652px',
    },
    title:{
        textTransform:'uppercase',
        fontSize: '45px',
        fontWeight: 700,
        marginTop: '20pt',
        marginBottom: '20pt',
    },
    posterInfo:{
        backgroundColor: '#fff',
        height: '307px',
        width: '387px',
        position: 'absolute',
        left: '8.74%',
        right: '7.42%',
        top: '44.52%',
        bottom: '8.41%',
    },
    dogInfo: {
        margin: '40px 0 10px',
    },
    dogName: {
        fontSize: '40px',
        padding: '5px',
        fontFamily: 'Courier-Bold',
    },
    breed: {
        fontSize: '14px',
        fontWeight: 'light',
        padding: '5px',
    },
    image: {
        width: '236px',
        height: '194px',
        borderRadius: '5px',
        border:'10px solid #fff',
        margin: '0 auto',
        paddingTop:'5px',
        paddingBottom:'5px',
        paddingLeft:'5px',
        paddingRight:'5px',
    },
    posterDescription: {
        height: '180pt',
        padding: '5px 50px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-evenly',
    },
    descriptionText: {
        fontSize: '12px',
    },
    lastDescriptionText: {
        fontSize: '14px',
        marginTop: '20px',
        marginBottom: '0',
        padding: '0',
    },
    phone:{
        fontSize: '20px',
        fontFamily: 'Helvetica-Bold',
        marginBottom: '10px',
        padding: '0',
    }
  })
  return (
    <Document title={`${t('LostDogPostPage.fileName')}${currentDate}`}>
        <Page size='A4'>
            <View style={styles.poster} key={id}>
                <Image style={styles.pageBackground} src={Images.flyerBG} />
                <Text style={styles.title}>{t('DogPoster.lost')}</Text>
                <div style={styles.posterInfo}>
                    <div style={styles.dogInfo}>
                        <Text style={styles.dogName}>{dogName}</Text>
                        <Text  style={styles.breed}>{breed} </Text>
                    </div>
                    <div style={styles.posterDescription}>
                        <Text style={styles.descriptionText}>{t('DogPoster.date')} {dateFormat(date!)} {t('DogPoster.address')} {address} </Text>
                        <Text style={styles.descriptionText}>{description}</Text>
                        <Text style={styles.lastDescriptionText}>{t('DogPoster.contact')} {ownersName} {t('DogPoster.at')}</Text>
                        <Text style={styles.phone}>{phoneNumber}</Text>
                    </div> 
                </div>  
                <Image style={styles.image} src={images[0]}/>
            </View>
        </Page>
    </Document>
  )
}

export default PosterPDF
