import './ProfilePage.scss';
import { Row, Col, Image, Button, Layout, Table, Space, Avatar, Modal, Divider } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LostPostInterface } from '../../services/lostDogs/LostPostInterface';
import { LostDogService } from '../../services/lostDogs/LostDogService';
import Navbar from '../../components/Navbar/Navbar';
import Footer from '../../components/Footer/Footer';
import dogAvatar from '../../img/dog-avatars/dogAvatar';
import { ColumnsType } from 'antd/lib/table';
import { FaEdit, FaPrint, FaTrashAlt } from 'react-icons/fa'
import { ExclamationCircleOutlined } from '@ant-design/icons';
import EditForm from '../../components/EditForm/EditForm';
import { PDFViewer } from '@react-pdf/renderer';
import PosterPDF from '../../components/Poster/PosterPDF';

const ProfilePage: React.FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate()
    const addPost = () => {
        navigate('/publishlost')
    }
    const username = localStorage.getItem('user');
    const email = localStorage.getItem('email');
    const avatar = Object.values(dogAvatar)[Math.floor(Math.random()*5)]
    const lostPostCard: LostPostInterface = {
        id: 0,
        images: [''],
        dogName: '',
        description: '',
        breed: '',
    }

    const [loading, setLoading] = useState(false);
    const [lostPosts, setLostPosts] = useState<any[]>([lostPostCard]);
    const [openDeleteWarning, setOpenDeleteWarning] = useState(false);
    const [openEditModal, setOpenEditModal] = useState(false);
    const [viewPDF, setViewPDF] = useState(false)
    const [currentPost, setCurrentPost] = useState<LostPostInterface | null>();

    const loadPosts = async () => {
        setLoading(true);
        const posts : LostPostInterface[] = await LostDogService.getMyLostDogPosts();
        setLostPosts(posts);
        setLoading(false);
    };

    useEffect(() => {
        loadPosts();
    }, []);
   
    const editPost = async (id: number, editedData : LostPostInterface) => {
        await LostDogService.editPost(id, editedData)
        loadPosts()
        setOpenEditModal(false)
    }

    const deletePost = async (id : number) => {
        setOpenDeleteWarning(false);
        await LostDogService.deletePost(id);
        const updatedPosts = lostPosts.filter(post => post.id !== id);
        setLostPosts(updatedPosts)
    }

    const handleCancel = () => {
        setOpenDeleteWarning(false);
        setOpenEditModal(false);
        setViewPDF(false)
        setCurrentPost(null);
    };
    
    const closeEditModal = () => {
        setOpenEditModal(false)
    }
    
    const showPDF = (lostPost : LostPostInterface) => {
        setCurrentPost(lostPost);
        setViewPDF(true);
    };

    const showEdit = (lostPost : LostPostInterface) => {
        setCurrentPost(lostPost)
        setOpenEditModal(true)
    }

    const showDelete = (lostPost : LostPostInterface) => {
        setCurrentPost(lostPost)
        setOpenDeleteWarning(true)
    }

    interface DataType {
        dogName: string;
        size: string;
        description: string;
        breed: string;
        id: number;
        images: any;
        date: string;
        address: string; 
        owner: any; 
        phoneNumber: string,
    }

    const columns: ColumnsType<DataType> = [
        {
          title: '',
          dataIndex: 'images',
          key: 'images',
          render: (images, lostPosts) => { 
            return <Link key={lostPosts.id} to={`/lostDog/${lostPosts.id}`}>
                <Avatar src={images[0]} className='postImg'/>
            </Link>},
        },
        {
            title: t('ProfilePage.dogName'),
            dataIndex: 'dogName',
            key: 'dogName',
            width: '30%',
            render: (dogName, lostPosts) => { 
                return <Link key={lostPosts.id} to={`/lostDog/${lostPosts.id}`}>
                    <p className='dogName'>{dogName}</p>
                    <p className='dogBreed'>{lostPosts.breed}</p>
                </Link>},
        },
        {
            title: t('ProfilePage.dogDescription'),
            dataIndex: 'description',
            key: 'description',
            render: (description, lostPosts) => {
                return <div className='' key={lostPosts.id}>
                    {description.length > 100 ? description.substr(0,100)+'...' : description}
                </div>
                },
        }, 
        {
            title: '',
            dataIndex: 'action',
            key: 'action',
            width: '10%',
            render: (id, lostPosts) => {
                return <Space size="large" className='editIcons' key={id}>
                    <a onClick={()=> showPDF(lostPosts)}><FaPrint className='editIcon'/></a>
                    <a onClick={()=> showEdit(lostPosts)}><FaEdit className='editIcon'/></a>
                    <a type='link' onClick={()=> showDelete(lostPosts)}><FaTrashAlt className='editIcon'/></a>
                </Space>
            },
        },
    ];

    return (
        <Layout className="profileContainer">
            <Navbar/>
            <Row className='profileRow'>
                <Col span={7} className='userContainer'>
                    <div className="userData">
                        <div className="userPicture">
                            <Image className='userAvatar' src={avatar} alt='user' preview={false} />
                        </div>
                        <div className="userInfo">
                            <p className='textH2'>{t('ProfilePage.dataTitle')}</p>
                            <div className="info">
                                <p><span style={{fontWeight: 'bold'}}>{t('ProfilePage.name')}</span><br/>{username}</p>
                                <p><span style={{fontWeight: 'bold'}}>{t('ProfilePage.email')}</span><br/>{email}</p>
                            </div>
                        </div>
                        <Button className='btn btnLarge primaryBtnLight postBtn' type='primary' onClick={addPost}>{t('ProfilePage.add')}</Button>
                    </div>    
                </Col>
                <Col span={17} className='userPostsContainer'>
                    <div className="userPosts">
                        <p className='profileTitle textH5'>{t('ProfilePage.title')}</p>
                        { lostPosts.length && lostPosts[0].dogName ? 
                            <div className="postsTable">
                                <Table columns={columns} dataSource={lostPosts} pagination={false} rowKey="id" />
                                <Modal 
                                    open={viewPDF}
                                    footer={null}
                                    onCancel={handleCancel}
                                    className='PDFContainer'
                                    width={750}
                                    bodyStyle={{height:650}}
                                    style={{ top: 20 }}
                                    afterClose={handleCancel}
                                >
                                    { currentPost && 
                                    
                                    <PDFViewer className='pdfViewer' width={700}>
                                        <PosterPDF key={currentPost.id} dogName={currentPost.dogName} breed={currentPost.breed} images={currentPost.images!} description={currentPost.description} date={currentPost.date} address={currentPost.address} ownersName={currentPost.ownersName} phoneNumber={currentPost.phoneNumber} id={currentPost.id}/>
                                    </PDFViewer>
                                    }
                                </Modal>
                                <Modal title={<div className='modalTitle'>{t('ProfilePage.editTitle')}</div>} 
                                    open={openEditModal}
                                    width={550}
                                    footer={null}
                                    onCancel={handleCancel}
                                    >
                                    { currentPost && 
                                    <EditForm postData={currentPost} closeEditModal={closeEditModal} editPost={editPost}/>
                                    }
                                </Modal>
                                { currentPost && 
                                <Modal title={<div className='modalTitle'><ExclamationCircleOutlined className='modalIcon' /> {t('ProfilePage.deleteTitle')}</div>} 
                                    open={openDeleteWarning}
                                    onOk={()=>deletePost(currentPost.id)}
                                    onCancel={handleCancel}
                                    okText={t('ProfilePage.delete')}
                                    cancelText={t('ProfilePage.cancel')}
                                    okType='danger'
                                    >
                                    <p>{t('ProfilePage.deleteWarning1')}</p>
                                    <p>{t('ProfilePage.deleteWarning2')}</p>
                                </Modal>
                                }
                            </div>
                            : <div className='noPostContainer flexCenter'>
                                <Divider/>
                                <p>{t('ProfilePage.noPosts')}</p>
                                <Button className='btn btnLarge primaryBtnDark postBtn' type='primary' onClick={addPost}>{t('ProfilePage.add')}</Button>                            
                            </div>
                        }
                    </div>                    
                </Col>
            </Row>
            <Footer/>
        </Layout>
    );
}

export default ProfilePage