import './PublishLostPage.scss';
import React from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useTranslation } from 'react-i18next';
import { useState, useEffect, useRef } from 'react';
import { LostDogInterface } from '../../services/lostDogs/LostDogInterface'
import { Col, Typography, Form, Input, Radio, DatePicker, Upload, Button, Checkbox, message, Layout, Select } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { LostDogService } from '../../services/lostDogs/LostDogService';
import {  IMG_MIN_SIZE, IMG_MAX_SIZE, IMG_MAX_COUNT } from '../../configs';
import Maps from '../../components/Maps/Maps';
import moment from 'moment';
import { BreedsInterface } from '../../services/lostDogs/BreedsInterface';
import Navbar from '../../components/Navbar/Navbar';
import Footer from '../../components/Footer/Footer';
import { getBreedOptionsByLanguage } from '../../utils/util'; 
import { AxiosError } from 'axios';
import { UserService } from '../../services/users/UserService';

const PublishLostPage: React.FC = () => {
    const { Title, Paragraph } = Typography;
    const { Content } = Layout;
    const { TextArea } = Input
    const recaptchaKey = process.env.REACT_APP_RECAPTCHA_KEY
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const lostForm: LostDogInterface = {
        id: 0,
        dogName: '',
        breed: '',
        gender: '',
        date: '',
        owner: {
            username: '',
            email: '',
        },
        phoneNumber: '',
        email: '',
        description: '',
        dogSize: '',
        address: '',
        latitude: '',
        longitude: '',
    };

    const [lostData, setLostData] = useState<LostDogInterface>(lostForm);
    const [saving, setSaving] = useState(["",""])
    const [breedOptions, setBreedOptions] = useState<{label: string, value: string}[]>()
    const [acceptedTerms, setAcceptedTerms] = useState<Boolean>(false)
    const [recaptcha, setRecaptcha] = useState<Boolean>(false)
    const [errorMessage, setErrorMessage] = useState('')
    useEffect(() => {
        if (saving[0] === 'saving'){
            message.loading(<p>{t('PublishLostPage.saving')}</p>)
        } else if (saving[0] === 'success') {
            message.success(<p>{t('PublishLostPage.success')}</p>, 3)
        } else if (saving[0] === 'failed') {
            message.error(<p>{t('PublishLostPage.fail')}{saving[1]}</p>, 3)
        };
        const getBreeds = async () => {
            const breeds : BreedsInterface[] = await LostDogService.getDogBreeds()
            setBreedOptions(getBreedOptionsByLanguage(window.navigator.language, breeds))
        }
        getBreeds()
    }, [saving]);

    const handleChange = (e: any) => {
        const { name, value } = e.target;
        setLostData(oldData => ({
            ...oldData,
            [name]: value
        }));
    }

    const handleSelect = (name: string, value: any) => {
        setLostData(oldData => ({
            ...oldData,
            [name]: value
        }));
    }

    const handleRadio = (name: string, e : any) =>{
        const {value} = e.target;
        setLostData(oldData => ({
            ...oldData,
            [name]: value
        }));
    }

    const handlerPreUpload = (file: any) => {
        const isImgMaxSize = file.size / 1024 / 1024 < IMG_MAX_SIZE;
        const isImgMinSize = file.size / 1024 / 1024 > IMG_MIN_SIZE;

        if (!isImgMaxSize) {
            message.error(<div>{t('PublishLostPage.smallerImg')}</div>);
            return Upload.LIST_IGNORE
        } else if (!isImgMinSize) {
            message.error(<div>{t('PublishLostPage.biggerImg')}</div>);
            return Upload.LIST_IGNORE
        }

        return isImgMaxSize && isImgMinSize;
    }

    const handleMap = (lat : number, lng : number, add : string) => {
        const latitude = lat.toString();
        const longitude = lng.toString();
        const address = add;
        setLostData(oldData => ({
            ...oldData,
            latitude: latitude,
            longitude : longitude,
            address : address,
        }));
    }
    
    const disableFutureDays = (current : any) => {
        return current && current > moment().endOf('day')        
    }
    //@ts-ignore
    const dummyRequest = ({ file, onSuccess }) => {
        setTimeout(() => {
            onSuccess('ok');
        }, 0);
    };

    const handleTerms = () => {
        setAcceptedTerms(true)
    }
    const captcha = useRef(null)
    const handleRecaptcha = () => {
        if(captcha){
            setRecaptcha(true)
        }      
    }
    const handleSubmit = async (e: any) => {
        if (recaptcha && acceptedTerms){
            setSaving(['saving','']);
            const files = e.files.fileList;
            const data = new FormData();
            for (let i = 0 ; i < files.length ; i++) {
                data.append(`files[]`, files[i].originFileObj);
            }
            data.append('dogName', lostData.dogName);
            data.append('breed', lostData.breed);
            data.append('gender', lostData.gender);
            data.append('date', moment(lostData.date).format());
            data.append('phoneNumber', lostData.phoneNumber);
            data.append('description', lostData.description);
            data.append('dogSize', lostData.dogSize);
            data.append('address', lostData.address);
            data.append('latitude', lostData.latitude);
            data.append('longitude', lostData.longitude);     
            try {
                await LostDogService.publishLost(data);
                setSaving(['success','']);
                form.resetFields();
            } catch (err) {      
                const errorStatusCode = (err as AxiosError).response?.status;
                if (errorStatusCode === 400) {
                    setErrorMessage(t('PublishLostPage.unsupportedCountry'))
                } else if (errorStatusCode === 401){
                    setErrorMessage(t('PublishLostPage.existingDog'))
                }
                setSaving(['failed', errorMessage]);
            }
        } else if (!recaptcha) {
            message.error(<div>{t('PublishLostPage.recaptcha')}</div>)
        } else if (!acceptedTerms) {
            message.error(<div>{t('PublishLostPage.acceptTerms')}</div>)
        }
    }

    return (
        <Layout className="formRow">
            <Navbar/>
            <Content>
                <Col span={14} className='formContainer'>
                    <Title className='formTitle'>{t('PublishLostPage.title')}</Title>
                    <Form layout='horizontal' className='lostForm' form={form} onFinish={handleSubmit}>
                        <Form.Item name='dogName' className='formGroup' required >
                            <Input name='dogName' placeholder={t('PublishLostPage.dogName')} required onChange={handleChange} className='formInput'></Input>
                        </Form.Item>
                        <Form.Item name='breed' className='formGroup' required >
                            <Select
                                showSearch
                                options={breedOptions}
                                placeholder={t('PublishLostPage.dogBreed')}
                                filterOption={(inputLabel, option) =>
                                    option?.label.toUpperCase().indexOf(inputLabel.toUpperCase()) !== -1
                                }
                                onChange={(value) => handleSelect('breed', value)}
                                >
                            </Select>
                        </Form.Item>
                        <Form.Item name='gender' className='radioGroup' required>
                            <Radio.Group onChange={(value) => handleRadio('gender', value)}>
                                <Radio value='Male'>{t('PublishLostPage.male')}</Radio>
                                <Radio value='Female'>{t('PublishLostPage.female')}</Radio>
                            </Radio.Group>
                        </Form.Item>
                        <Form.Item name='lostdate' className='formGroup' required>
                            <DatePicker name='lostdate' className='formInput' placeholder={t('PublishLostPage.lostDate')} disabledDate={current => disableFutureDays(current)} onChange={(value) => handleSelect('date', value)}/>
                        </Form.Item>
                        <Form.Item name='address' className='formGroup formInput'>
                            <Maps handleMap={handleMap} placeholder={t('PublishLostPage.address')}/>
                        </Form.Item>
                        <Form.Item name='phoneNumber' className='formGroup' required>
                            <Input className='formInput' name='phoneNumber' placeholder={t('PublishLostPage.phoneNumber')} required onChange={handleChange}></Input>
                        </Form.Item>
                        <Form.Item name='description' className='formGroup' required>
                            <TextArea showCount name='description' placeholder={t('PublishLostPage.descriptionInput')} maxLength={300} onChange={handleChange} />
                        </Form.Item>
                        <Form.Item name='dogSize' className='formGroup' required>
                            <>
                                <p>{t('PublishLostPage.dogSize')}</p>
                                <Radio.Group className='radioGroup'  onChange={(value) => handleRadio('dogSize', value)}>
                                    <Radio value='VerySmall' className='sizeRadio'>{t('PublishLostPage.verySmall')}</Radio>
                                    <Radio value='Small' className='sizeRadio'>{t('PublishLostPage.small')}</Radio>
                                    <Radio value='Medium' className='sizeRadio'>{t('PublishLostPage.medium')}</Radio>
                                    <Radio value='Large' className='sizeRadio'>{t('PublishLostPage.large')}</Radio>
                                </Radio.Group>
                            </>
                        </Form.Item>
                        <Form.Item name='files' className='formGroup' valuePropName="checked" rules={[{
                            required: true,
                            message: <div>{t('PublishLostPage.imgError')}</div>,
                        },
                        ]}>
                            <Upload.Dragger
                                className='photos'
                                listType='picture-card'
                                multiple
                                //@ts-ignore
                                customRequest={dummyRequest}
                                accept='.png,.jpeg,.jpg,.gif'
                                maxCount={IMG_MAX_COUNT}
                                beforeUpload={(file) => handlerPreUpload(file)}
                            >
                                {t('PublishLostPage.dragPhotos')} <br />
                                <div className='uploadText'> {<UploadOutlined />}{t('PublishLostPage.clickPhotos')}</div>
                            </Upload.Dragger>
                        </Form.Item>
                        <Form.Item name='captcha' className='captcha' required>
                            <ReCAPTCHA sitekey={recaptchaKey!} onChange={handleRecaptcha} ref={captcha}/>
                        </Form.Item>
                        <Form.Item name='terms' className='formGroup' valuePropName="checked" required>
                            <Checkbox onChange={handleTerms}>
                                {t('PublishLostPage.terms')}
                            </Checkbox>
                        </Form.Item>
                        <Form.Item required>
                            <>
                                <Paragraph className='requiredFields'>{t('PublishLostPage.requiredFields')}</Paragraph>
                                <Button className='btn btnLarge secondBtnDark uploadBtn' type='primary' htmlType='submit'>{t('PublishLostPage.upload')}</Button>
                            </>
                        </Form.Item>
                    </Form>
                </Col>
            </Content>
            <Footer/>
        </Layout>
    );
}

export default PublishLostPage;