import React from 'react'
import './LoginPage.scss';
import { useState, useEffect } from 'react';
import { useMutation } from 'react-query';
import { SessionService } from '../../services/users/SessionService';
import { SessionInterface } from '../../services/users/SessionInterface';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { VerificationService } from '../../services/users/VerificationService';
import { Col, Image, Form, Input, Button, Typography, notification, message, Layout } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons'
import { AxiosError } from 'axios';
import Navbar from '../../components/Navbar/Navbar';
import Footer from '../../components/Footer/Footer';
import { UserService } from '../../services/users/UserService';

const LoginPage : React.FC = () => {
    const {Title} = Typography;
    const { Content } = Layout;
    const { t } = useTranslation();
    const navigate = useNavigate()

    const loginForm : SessionInterface = {
        email: "",
        password: "",
    };

    const [loginData , setLoginData] = useState<SessionInterface>(loginForm);
    const mutation = useMutation((loginData : SessionInterface) => SessionService.loginUser(loginData));
    
    const handleChange = (e: any) => {
        const {name, value} = e.target;
        setLoginData(oldData => ({
            ...oldData,
            [name]: value
        }));
    }

    const openNotification = (errorMsg : string) => {
        const key = `open${Date.now()}`;      
        const btn = (                                
            <Button type="primary" size="small" 
                onClick={ async () => {
                    message.success(t('LoginPage.resendSuccess'));
                    VerificationService.resendMail(loginData.email);                                                                         
                }}>
                {t('LoginPage.resend')}
            </Button>
          );
        notification.open({
            message: t('LoginPage.error'),
            description: errorMsg,
            key,
            placement: 'top',
            top: 50,
            duration: 0,
            btn,
            onClose: () => navigate('/register'),
        });     
      };

    const handleSubmit = (e: any) => {
        mutation.mutate(loginData,
            {
                onSuccess: async () => {
                    navigate('/profile');
                    const userName = await UserService.getUsername(loginData.email)
                    localStorage.setItem('user', userName)
                    localStorage.setItem('email', loginData.email)
                },
                onError: (error) => {
                    const errorStatusCode = (error as AxiosError).response?.status;
                    if (errorStatusCode === 412) {
                        openNotification(t('LoginPage.confirmEmail'))
                    } else if (errorStatusCode === 400){
                        message.error(t('LoginPage.invalidCredentials'));
                    } else if (errorStatusCode === 401){
                        message.error(t('LoginPage.userNotFound'));
                    }
                }
            }
        );
    }

    return(
        <Layout className='loginContainer'>
            <Navbar/>
            <Content>
                <div className='loginBG'>
                    <svg width="1027" height="501" viewBox="0 0 1027 501" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M-0.000432766 17.4999L-0.000319015 500.875L1026.8 501C966.542 396.816 884.386 299.099 780.514 216.826C540.291 26.5533 242.65 -33.008 -0.000432766 17.4999Z" fill="#75E3EA"/>
                    </svg>
                </div>
                <Col xs ={15} sm={15} md={10} lg={10} xl={7} className="loginForm">
                    <Title className='formTitle'>{t('LoginPage.title')}</Title>
                    <Title level={2} className='formSubtitle'>{t('LoginPage.subtitle')}</Title>
                    <Form onFinish={handleSubmit} layout='vertical' className='form'>
                        <Form.Item name='email' className='formGroup'>
                            <Input name='email' className='formInput' type='email' placeholder={t('LoginPage.email')} prefix={<UserOutlined />} onChange={handleChange}></Input>
                        </Form.Item>
                        <Form.Item  name='password' className='formGroup'>
                            <Input.Password name='password' className='formInput' placeholder={t('LoginPage.password')} required prefix={<LockOutlined />} onChange={handleChange}></Input.Password>
                        </Form.Item>
                
                        {mutation.isLoading ? <p>{t('LoginPage.sending')}</p> : null}

                        <Form.Item>
                            <Button className='btn btnLarge secondBtnDark loginBtn' type='primary' htmlType='submit'>{t('LoginPage.login')}</Button>
                            <p className='btnText'>{t('LoginPage.noAccount')} <Link to={'/login'} className='signinLink'>{t('LoginPage.signUp')}</Link></p>
                        </Form.Item>
                    </Form>
                </Col>
            </Content>
            <Footer/>
        </Layout>
    );
}

export default LoginPage;