import heroImg from './HeroImg.png'
import registerIcon from './RegisterIcon.png'
import publishIcon from './PublishIcon.png'
import shareIcon from './ShareIcon.png'
import registerGif from './registerGif.gif'
import publishGif from './publishGif.gif'
import shareGif from './shareGif.gif'
import flyerBG from './FlyerBG.png'

const Images = {
    'heroImg': heroImg,
    'registerIcon': registerIcon,
    'publishIcon': publishIcon,
    'shareIcon': shareIcon,
    'registerGif': registerGif,
    'publishGif': publishGif,
    'shareGif': shareGif,
    'flyerBG': flyerBG,
}

export default Images