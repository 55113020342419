import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { VerificationService } from "../../services/users/VerificationService";
import { message, Row } from "antd";
import Message from '../../components/Message';

const ConfirmEmail : React.FC = () => {
    const {t} = useTranslation()
    const navigate = useNavigate();
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('token') || '';

    const [messageError, setMessageError] = useState<string>();

    useEffect(() => {
        const emailConfirm = async () => {
            let errorMessage = '';
            try {
                const emailConfirmationResponse = await VerificationService.emailConfirmation(token);                                  
                if (emailConfirmationResponse.status !== 200) {
                    errorMessage = 'Validation failed';                                        
                }
            } catch (err) {
                errorMessage = (err as Error).message; 
            }
            if (errorMessage !== '') {
                return (
                    setMessageError(errorMessage)                    
                )
            } else {
                navigate('/login');
                message.success('Registration complete, please log in', 5);
            }
        };
        emailConfirm();
    }, []);
        
    return(
        <Row className="verificationRow" justify='center' >
            <Message title={t('ConfirmEmailPage.title')} subtitle={messageError!} />
        </Row>
    )
}
export default ConfirmEmail
