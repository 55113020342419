import './Map.scss'
import { useMemo, useState, useCallback, useRef } from 'react'
import { GoogleMap, Marker } from '@react-google-maps/api'
import { useTranslation } from 'react-i18next';
import PlacesAutocomplete, {geocodeByAddress, getLatLng} from 'react-places-autocomplete'
import { Input } from 'antd';

type LatLngLiteral = google.maps.LatLngLiteral;
type MapOptions = google.maps.MapOptions;

const Map = (props : any) => {
  const { handleMap, placeholder } = props
  const { t } = useTranslation(); 

  const mapRef = useRef<GoogleMap>();
  const center = useMemo<LatLngLiteral>(() => ({lat: -34.606479, lng: -58.435722}), []);
  const options = useMemo<MapOptions>(() => ({
    clickableIcons: false,
    mapTypeControl: false,
    streetViewControl: false,
    fullscreenControl: false,
  }), []);

  const onLoad = useCallback((map : any) => (mapRef.current = map), [])

  const [add, setAdd] = useState('')
  const [coordinates, setCoordinates] = useState({
    lat:-34.606479,
    lng:-58.435722,
  })

  const handleSelect = async (value : string)=> {
    const results = await geocodeByAddress(value);
    const latLng = await getLatLng(results[0]);
    setAdd(value);
    setCoordinates(latLng);
    mapRef.current?.panTo(latLng);
    handleMap(latLng.lat, latLng.lng, value);
  }

  return (
    <>
      <PlacesAutocomplete 
          value={add}
          onChange={(value) => {setAdd(value)}}
          onSelect={handleSelect}
          >
          {({ getInputProps, suggestions, getSuggestionItemProps, loading}) => (
            <div>
              <Input {...getInputProps({
                name:'ownersAddress',
                placeholder: placeholder,
                className: 'location-search-input',
              })}/>
              <div className="autocomplete-dropdown-container">
                {loading && <div>loading...</div>}
                {suggestions.map(suggestion => {
                  const className = suggestion.active
                    ? 'suggestion-item--active'
                    : 'suggestion-item';
                  const style = suggestion.active
                    ? {backgroundColor: '#cccccc', cursor: 'pointer'}
                    : {backgroundColor: '#fff', cursor: 'pointer'}
                    return (
                      <div
                        {...getSuggestionItemProps(suggestion, {
                          className,
                          style,
                        })}
                        key={suggestion.placeId}
                      >
                        <span>{suggestion.description}</span>
                      </div>
                    );
                  })}
              </div>
            </div>
            )}
        </PlacesAutocomplete>
      <GoogleMap
        options={options}
        zoom={15}
        center={center}
        mapContainerClassName="mapContainer"
        onLoad={onLoad}
        >
          <Marker position={coordinates}/>
      </GoogleMap>
    </>
  )
}

export default Map