import './DogPoster.scss'
import { Col, Image, Row, Typography } from 'antd';
import { LostPostInterface } from '../../services/lostDogs/LostPostInterface'
import { useTranslation } from 'react-i18next';
import { dateFormat } from '../../utils/util';

const DogPoster = (props : LostPostInterface) => {
  const { t } = useTranslation();
  const { dogName, description, images, breed, date, address, id, ownersName, phoneNumber } = props;
  const { Title, Paragraph } = Typography;

  return (
    <div className='dogPoster' key={id}>
      <Title level={2}>{t('DogPoster.lost')}</Title>
      <div className="posterInfo">
        <div className="posterTitleRow">
            <Paragraph className='posterDogName'>{dogName}</Paragraph>
            <Paragraph className='posterDogBreed'>{breed} </Paragraph>
        </div>
        <div className='posterDescription'>
          <Paragraph className='descriptionText'>{t('DogPoster.date')} {dateFormat(date!)} {t('DogPoster.address')}<br/> {address} </Paragraph>
          <Paragraph className='descriptionText'>{description}</Paragraph>
          <Paragraph className='descriptionText'>{t('DogPoster.contact')} {ownersName} {t('DogPoster.at')}</Paragraph>
          <Paragraph className='phone'>{phoneNumber}</Paragraph>
        </div>
      </div>  
      <Image className='posterImg' src={images[0]} alt='dogPicture' preview={false}/>
    </div>
  )
}

export default DogPoster