import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
    .use(LanguageDetector)
    .use(initReactI18next).init ({

        fallbackLng: 'en',
        resources: {
            es: {
                translation: {
                    HomePage: {
                        title: 'Perdiste a tu perro?',
                        subtitle: 'Lost Pet ayuda a conectar perros con humanos.',
                        getStarted: 'Empecemos',
                        howItWorks: '¿Cómo funciona?',
                        register: 'Registrate',
                        registerSub: 'Creá tu cuenta LostPet',
                        publish: 'Publicá',
                        publishSub: 'Creá una publicación con fotos e información importante sobre tu perro',
                        share: 'Compartí',
                        shareSub: 'Compartí tu publicación para encontrar a tu mascota',
                        ctaText: 'No busques más, encontrá a tu perro!',
                        ctaBtn: 'Ingresar',
                    },
                    PublishLostPage: {
                        title: "Ingresá un perro perdido",
                        dogName: "Nombre del perro",
                        dogNameInput: 'Bonzo',
                        breed: 'Raza',
                        dogBreed: "Elige la raza de tu perro",
                        sex: 'Sexo',
                        male: 'Macho',
                        female: 'Hembra',
                        country: 'País',
                        countryInput: 'Selecciona tu país',
                        lostDate: '¿Cuándo se perdió?',
                        lostDateInput: 'Elige una fecha',
                        ownersName: 'Nombre del dueño',
                        ownersNameInput: 'Pablo',
                        address: '¿Dónde se perdió?',
                        ownersAddressExample: 'Av Juan B. Justo 512, Ciudad de Mendoza, Mendoza',
                        phoneNumber: 'Teléfono de contacto',
                        phoneNumberExample: '(0261) 429-4020',
                        email: 'Email',
                        emailExample: 'ejemplo@mail.com',
                        descriptionInput: 'Contanos qué caracteriza a tu perro en menos de 300 caracteres',
                        dogSize: 'Tamaño: ',
                        verySmall: 'Muy pequeño',
                        small: 'Pequeño',       
                        medium: 'Mediano',
                        large: 'Grande',  
                        dragPhotos: 'Arrastra tus fotos aquí',
                        clickPhotos: ' Click para agregar',
                        terms: 'Acepto los términos y condiciones',
                        requiredFields: 'Los campos con * son obligatorios y serán publicados',
                        upload: 'Publicar',
                        smallerImg: 'La imágen debe pesar menos de 2MB',
                        biggerImg: 'La imágen debe pesar más de 100KB',
                        imgError: 'Debes agregar una imagen',
                        saving: 'Guardando...',
                        success: 'Creado con éxito',
                        fail: 'Carga fallida: ',
                        acceptTerms: 'Por favor, acepte nuestros términos',
                        recaptcha: 'Confirme que no es un robot',
                        unsupportedCountry: 'LostPet no es compatible con la ubicación que has seleccionado',
                        existingDog: 'Ya existe una publicación de su perro',
                    },

                    RegisterPage: {
                        title: 'Crea una cuenta',
                        subtitle: 'Registrate para encontrar y buscar perros!',
                        userName: 'Usuario',
                        email: 'Email',
                        password: 'Contraseña',
                        signUp: 'Registrarse',
                        alreadyHave: 'Ya tenes una cuenta?',
                        signIn: 'Iniciá sesión',
                        sending: 'Enviando...',
                        correct: 'Usuario creado',
                        error: 'Hubo un error creando el usuario',
                        mailError: 'Ya existe un usuario con ese email',
                    },

                    LoginPage: {
                        title: 'Iniciar Sesión',
                        subtitle: 'Ingresá a tu cuenta para publicar tu perro perdido',
                        email: 'E-mail',
                        emailInput: 'ejemplo@mail.com',
                        password: 'Contraseña',
                        passwordInput: 'Ingrese su contraseña',
                        login: 'Ingresar',
                        noAccount: 'Todavía no tenés una cuenta?',
                        signUp: 'Registrate',
                        sending: 'Enviando...',
                        error: 'Hubo un error iniciando sesión',
                        confirmEmail: 'Es necesario confirmar el e-mail',
                        userNotFound: 'El usuario no existe',
                        resend: 'Reenviar link',
                        resendSuccess: 'El email de confirmación ha sido reenviado',
                        invalidCredentials: 'Credenciales inválidas',
                    },

                    ProfilePage: {
                        title: 'Mis publicaciones',
                        dataTitle: 'Mis datos',
                        name: 'Nombre: ',
                        email: 'Email: ',
                        dogName: 'Nombre',
                        dogDescription: 'Descripción',
                        loading: 'Cargando...',
                        add: 'Agregar publicación',
                        deleteTitle: 'Atención',
                        deleteWarning1: 'Esta acción no puede revertirse',
                        deleteWarning2: '¿Está seguro de que desea eliminar la publicación?',
                        delete: 'Eliminar',
                        cancel: 'Cancelar',
                        editTitle: 'Editar publicación',
                        editSave: 'Guardar',
                        noPosts: 'Todavía no tenés publicaciones',
                    },

                    LostDogPostPage: {
                        contact: 'Contacto:',
                        phone: 'Teléfono:',
                        country: 'Pais:',
                        state: 'Partido:',
                        email: 'E-mail:',
                        sex: 'Sexo:',
                        breed: 'Raza:',
                        dogSize: 'Tamaño:',
                        description: 'Descripción:',
                        contactOwner: 'Contactar al dueño',
                        posterText: 'Descargá tu primer volante, imprimilo y empezá a repartirlo en tu barrio.',
                        posterText2: '¡Aumentá la difusión compartiéndolo en tus redes sociales!',
                        loading: 'Cargando...',
                        Gender: {
                            Female: 'Hembra',
                            Male: 'Macho',
                        },
                        DogSize: {
                            VerySmall: 'Muy pequeño',
                            Small: 'Pequeño',
                            Medium: 'Mediano',
                            Large: 'Grande',
                        },
                        fileName: 'VOLANTE_PERRO_PERDIDO_',
                    },

                    VerificationPage:{
                        title: 'Inserte su código de verificación',
                        subtitle: 'Revisa tu email, te hemos enviado un código',
                        send: 'Enviar',
                        error: 'Código incorrecto',
                    },

                    
                    VerificationMessagePage:{
                        title: 'Revise su email',
                        subtitle: 'Para continuar el registro ingrese al link de validacion que se envió a su cuenta de email',
                    },

                    ConfirmEmailPage:{
                        title: 'El token es invalido',
                    },
                    Navbar:{
                        home: 'Inicio',
                        lostDogs: 'Perros perdidos',
                        about: 'Nosotros',
                        register: 'Registrarse',
                        login: 'Iniciar sesión',
                        profile: 'Perfil',
                        myAccount: 'Mi cuenta',
                        logout: 'Cerrar sesión',
                        publish: 'Publicar',
                    },
                    LostDogsPage:{
                        title: 'Perros perdidos',
                        loading: 'Cargando...',
                        end: 'No hay mas registros',
                        selectBreed: 'Seleccione una raza',
                        selectDogSize: 'Seleccionar tamaño',
                        breed: 'Raza',
                        name: 'Nombre',
                        dogSize: 'Tamaño',
                        verySmall: 'Muy pequeño',
                        small: 'Pequeño',
                        medium: 'Mediano',
                        large: 'Grande',
                        noPosts: 'No hay publicaciones disponibles',
                    },
                    DogPoster:{
                        lost: 'Se busca',
                        date: 'Se perdió el',
                        breed: 'Raza:',
                        address: 'en',
                        contact: 'Contactarse con',
                        at: 'al',
                    },
                },
            },
            en: {
                translation: {
                    HomePage: {
                        title: 'Did you lose your pet?',
                        subtitle: 'Lost Pet helps to connect dogs with humans.',
                        getStarted: 'Get started',
                        howItWorks: 'How it works?',
                        register: 'Register',
                        registerSub: 'Create your LostPet account',
                        publish: 'Publish',
                        publishSub: 'Create a post with photos and important information about your dog',
                        share: 'Share',
                        shareSub: 'Share your post to find your pet',
                        ctaText: 'Do not look any further, find your dog!',
                        ctaBtn: 'Enter',
                    },
                    PublishLostPage: {
                        title: "Lost dog entry form",
                        dogName: "Dog name",
                        dogNameInput: 'Bonzo',
                        breed: 'Breed',
                        dogBreed: "Select your dogs breed",
                        sex: 'Sex',
                        male: 'Male',
                        female: 'Female',
                        country: 'Country',
                        countryInput: 'Select your country',
                        lostDate: 'When was it lost?',
                        lostDateInput: 'Select date',
                        ownersName: "Owner's name",
                        ownersNameInput: 'Paul',
                        address: "Where did your dog get lost?",
                        ownersAddressExample: '144 Republic St Pittsburgh, Pennsylvania(PA), 15211',
                        phoneNumber: 'Contact phone number',
                        email: 'Email',
                        description: 'Description',
                        descriptionInput: 'Tell us what characterizes your dog in less than 300 characters',
                        dogSize: 'Size: ',
                        verySmall: 'Very small',
                        small: 'Small',        
                        medium: 'Medium',
                        large: 'Large',     
                        dragPhotos: 'Drag your dog photos here OR',
                        clickPhotos: ' Click to upload',
                        terms:  'I accept terms of use',
                        requiredFields: 'The * fields are required and will be published',
                        upload: 'Upload',
                        smallerImg: 'Image must be smaller than 2MB!',
                        biggerImg: 'Image must be bigger than 100KB!',
                        imgError: 'You must add a image',
                        saving: 'Saving...',
                        success: 'Successfully created',
                        fail: 'Upload failed: ',
                        acceptTerms: 'Please, accept our terms',
                        recaptcha: 'Please, confirm you are not a robot',
                        unsupportedCountry: 'LostPet is not supported in the location you selected',
                        existingDog: 'LostDog already exists',
                    },
                    
                    RegisterPage: {
                        title: 'Create an account',
                        subtitle: 'Sign up to find and post lost dogs!',
                        userName: 'User name',
                        email: 'Email',
                        password: 'Password',
                        signUp: 'Sign Up',
                        alreadyHave: 'Already Have An Account?',
                        signIn: 'Sign In',
                        sending: 'Sending...',
                        correct: 'User created',
                        error: 'There was an error creating the user',
                        mailError: 'Email already in use',
                    },

                    LoginPage: {
                        title: 'Login',
                        subtitle: 'Enter your account to publish your lost dog',
                        email: 'Email address',
                        password: 'Password',
                        login: 'Login',
                        noAccount: "Still don't have an account?",
                        signUp: 'Sign Up',
                        sending: 'Sending...',
                        error: 'There was an error in the login',
                        confirmEmail: 'Email confirmation needed',
                        userNotFound: 'User not found',
                        resend: 'Resend link',
                        resendSuccess: 'The confirmation email has been resent',
                        invalidCredentials: 'Invalid credentials',
                    },
                    
                    ProfilePage: {
                        title: 'My posts',
                        dataTitle: 'My data',
                        name: 'Name: ',
                        email: 'Email: ',
                        dogName: 'Name',
                        dogDescription: 'Description',
                        loading: 'Loading...',
                        add: 'Add post',
                        deleteTitle: 'Warning',
                        deleteWarning1: 'This action cannot be undone',
                        deleteWarning2: 'Are you sure you want to delete this post?',
                        delete: 'Delete',
                        cancel: 'Cancel',
                        editTitle: 'Edit post',
                        editSave: 'Save changes',
                        noPosts: "You don't have posts yet",
                    },

                    LostDogPostPage: {
                        title: 'Information',
                        contact: 'Contact',
                        phone: 'Phone:',
                        country: 'Country:',
                        state: 'State:',
                        email: 'E-mail:',
                        sex: 'Sex:',
                        breed: 'Breed:',
                        dogSize: 'Size:',
                        description: 'Description:',
                        contactOwner: 'Contact the owner',
                        poster: 'Poster',
                        posterText: 'Download your first flyer, print it out and start handing it out in your neighborhood.',
                        posterText2: 'You can also increase awareness by saving the flyer as an image on your computer and uploading it to Facebook, Twitter and other social networks.',
                        loading: 'Loading...',
                        Gender: {
                            Female: 'Female',
                            Male: 'Male',
                        },
                        DogSize: {
                            VerySmall: 'Very small',
                            Small: 'Small',
                            Medium: 'Medium',
                            Large: 'Large',
                        },
                        fileName: 'FLYER_LOST_DOG_',
                    },

                    VerificationPage:{
                        title: 'Insert your verification code',
                        subtitle: 'Check your email, we send you a code',
                        send: 'Send',
                        error: 'Wrong code',
                    },

                    VerificationMessagePage:{
                        title: 'Check your email',
                        subtitle: 'To continue the registration open the validation link that was sent to your email account',
                    },
                    
                    ConfirmEmailPage:{
                        title: 'Invalid Token',
                    },
                    Navbar:{
                        home: 'Home',
                        lostDogs: 'Lost dogs',
                        about: 'About',
                        register: 'Register',
                        login: 'Login',
                        profile: 'Profile',
                        myAccount: 'My account',
                        logout: 'Logout',
                        publish: 'Publish',
                    },
                    LostDogsPage:{
                        title: 'Lost dogs',
                        loading: 'Loading...',
                        end: 'There are no more records',
                        selectBreed: 'Select breed',
                        selectDogSize: 'Select size',
                        breed: 'Breed',
                        name: 'Name',
                        dogSize: 'Size',
                        verySmall: 'Very small',
                        small: 'Small',
                        medium: 'Medium',
                        large: 'Large',
                        noPosts: 'No posts available',
                    },
                    DogPoster:{
                        lost: 'Lost Dog',
                        date: 'Lost since',
                        breed: 'Breed:',
                        address: 'in',
                        contact: 'call',
                        at: 'at',
                    },
                },
            },
        },
    });