import './LostDogsPage.scss';
import { Col, Typography, Form, Input, Select, Button, Layout } from 'antd';
import LostCard from '../../components/LostCard';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { LostPostInterface } from '../../services/lostDogs/LostPostInterface';
import { LostDogService } from '../../services/lostDogs/LostDogService';
import Navbar from '../../components/Navbar/Navbar';
import Footer from '../../components/Footer/Footer';
import { FilterInterface } from '../../services/lostDogs/FilterInterface';
import InfiniteScroll from 'react-infinite-scroll-component';
import { ITEMS_PER_PAGE } from '../../configs';
import { BreedsInterface } from '../../services/lostDogs/BreedsInterface';
import { getBreedOptionsByLanguage } from '../../utils/util';

const LostDogsPage: React.FC = () => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const { Paragraph } = Typography;
    const { Content } = Layout;

    const lostPostCard: LostPostInterface = {
        id: 0,
        images: [''],
        dogName: '',
        description: '',
        breed: '',
    }

    const [loading, setLoading] = useState(false);
    const [scrollPosts, setScrollPosts] = useState<LostPostInterface[]>([lostPostCard])
    const [hasMore, setHasMore] = useState(true)
    const [skip, setSkip] = useState(ITEMS_PER_PAGE)
    const [filters, setFilters] = useState<FilterInterface>()
    const [breedOptions, setBreedOptions] = useState<{ label: string, value: string }[]>()

    useEffect(() => {
        const loadPosts = async () => {
            setLoading(true);
            setScrollPosts((await LostDogService.getSearchedDogsPosts(ITEMS_PER_PAGE, 0)).data.items)
            setLoading(false);
        };
        const getBreeds = async () => {
            const breeds: BreedsInterface[] = await LostDogService.getDogBreeds()
            setBreedOptions(getBreedOptionsByLanguage(window.navigator.language, breeds))
        }
        loadPosts();
        getBreeds();
    }, []);

    const handleFilters = async (formFilters: FilterInterface) => {
        const filteredDogs: [LostPostInterface] = (await LostDogService.getSearchedDogsPosts(ITEMS_PER_PAGE, 0, formFilters)).data.items;
        setFilters(formFilters)
        setScrollPosts(filteredDogs)
        form.resetFields();
    }
    const fetchPosts = async () => {
        const page = await LostDogService.getSearchedDogsPosts(ITEMS_PER_PAGE, skip, filters)
        return page.data
    }

    const fetchData = async () => {
        const newPage = await fetchPosts()
        setScrollPosts([...scrollPosts, ...newPage.items])
        setSkip(skip + ITEMS_PER_PAGE);
        setHasMore(newPage.hasMore);
    }
    return (
        <Layout className="lostDogsContainer">
            <Navbar />
            <Content>
                <div className='lostDogsBG'>
                    <svg viewBox="0 0 1440 142" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path className='BGPath' d="M0 59.5805C205.015 111.594 452.723 142 719.5 142C986.711 142 1234.79 111.495 1440 59.3265V0H0V59.5805Z" fill="url(#paint0_linear_216_206)" />
                        <defs>
                        <linearGradient id="paint0_linear_216_206" x1="747.5" y1="-1.27828e-06" x2="744.009" y2="141.914" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#D9E8EF"/>
                        <stop offset="1" stopColor="#EAF2F5"/>
                        </linearGradient>
                        </defs>
                    </svg>
                </div>
                <Col span={24}>
                    <Form className='lostDogsFilter' labelCol={{ span: 6 }} wrapperCol={{ span: 17 }} form={form} onFinish={handleFilters}>
                        <Form.Item name='breed' className='formGroup'>
                            <Select
                                showSearch
                                options={breedOptions}
                                placeholder={t('LostDogsPage.breed')}
                                filterOption={(inputLabel, option) =>
                                    option?.label.toUpperCase().indexOf(inputLabel.toUpperCase()) !== -1
                                }
                            >
                            </Select>
                        </Form.Item>
                        <Form.Item name='dogSize' className='formGroup'>
                            <Select placeholder={t('LostDogsPage.dogSize')}>
                                <Select.Option value='VerySmall'>{t('LostDogsPage.verySmall')}</Select.Option>
                                <Select.Option value='Small'>{t('LostDogsPage.small')}</Select.Option>
                                <Select.Option value='Medium'>{t('LostDogsPage.medium')}</Select.Option>
                                <Select.Option value='Large'>{t('LostDogsPage.large')}</Select.Option>
                            </Select>
                        </Form.Item>
                        <Form.Item className='formBtn'>
                            <Button className='btn btnSmall secondBtnDark' htmlType='submit'>Buscar</Button>
                        </Form.Item>
                    </Form>
                </Col>
                <Col span={15} className='dogsContainer'>
                    <div className="cardGroup">
                        {scrollPosts.length ? 
                        <InfiniteScroll
                            dataLength={scrollPosts.length}
                            next={fetchData}
                            hasMore={hasMore}
                            loader={loading ? <h4>{t('LostDogsPage.loading')}</h4> : <></>}
                        >
                            <div className='cardContainer'>
                                {scrollPosts ? scrollPosts.map(lostPost =>
                                    <LostCard key={lostPost.id} dogName={lostPost.dogName} images={lostPost.images} 
                                    description={lostPost.description.length > 25 ? lostPost.description.substr(0,25)+'...' : lostPost.description} id={lostPost.id} />
                                ) : <></>}
                            </div>
                        </InfiniteScroll>
                     : <p>{t('LostDogsPage.noPosts')}</p> }
                    </div>
                </Col>
            </Content>
            <Footer />
        </Layout>
    );
}

export default LostDogsPage