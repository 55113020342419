import React from 'react'
import './Footer.scss'
import { Layout, Image } from 'antd'
import { MailOutlined, InstagramOutlined,  } from '@ant-design/icons'
import { Link } from 'react-router-dom'
import { TiSocialFacebook } from 'react-icons/ti'

const Footer = () => {
  const { Footer } = Layout
    return (
    <Footer className='footerContainer'>
      <div className="footerText">
        ©LOSTPET
      </div>
      <div className="logo">
          <Link to="/">
            <Image src="../logoBlack.png" alt="logo" preview={false} className="footerLogo" onClick={()=> {window.scrollTo({ top: 0, behavior: 'smooth' })}}/>
          </Link> 
      </div>
      <div className="contactIcons">
        <a href={'mailto:info.lostpet@gmail.com'} target={'_blank'} rel="noreferrer"><MailOutlined className='contactIcon flexCenter'/></a>
        <a href={'https://www.facebook.com/'} target={'_blank'} rel="noreferrer"><TiSocialFacebook className='contactIcon flexCenter'/></a>
        <a href={'https://www.instagram.com/'} target={'_blank'} rel="noreferrer"><InstagramOutlined className='contactIcon flexCenter'/></a>
      </div>
    </Footer>
  )
}

export default Footer