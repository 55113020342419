import { BreedsInterface } from "../services/lostDogs/BreedsInterface";

export const dateFormat = (date : string) => {
    return date.replace(/^(\d{4})-(\d{2})-(\d{2})$/g,'$3/$2/$1');
};

export const getBreedOptionsByLanguage = (navigatorLanguage : string, breeds : BreedsInterface[]) => {
    switch (navigatorLanguage) {       
        case 'es-ES':
        case 'es':
            return breeds.map((breed) => { return { label: breed.breed_spanish, value: breed.breed } })            
        case 'en-US' :
        case 'en':
            return breeds.map((breed) => { return { label: breed.breed, value: breed.breed } })
        default:
            return breeds.map((breed) => { return { label: breed.breed, value: breed.breed } })
    }
}