import { Card } from "antd"
import './LostCard.scss'
import { Link } from 'react-router-dom';
import { LostPostInterface } from "../services/lostDogs/LostPostInterface";

function LostCard (props : LostPostInterface) {    

    const { Meta } = Card;
    const { dogName, description, images, id } = props;

    return(
        <Link key={id} to={`/lostDog/${id}`}>
            <Card
                className='postCard' 
                hoverable
                cover={<img className='imgCard' alt="img" src={images[0]}/>}
                >
                <Meta title={dogName} description={description}/>
            </Card>
        </Link>
    )
}

export default LostCard