import axios, { AxiosError, AxiosResponse } from "axios";
export class ApiCaller {
    private static handleLogout(error: AxiosError){
        if(error.response?.status === 401){
            // @TODO: check JWT, reload cookies, etc
            window.location.replace("/login");
        }
    }

    public static async post(serviceUrl: string, postData: any, config?: any): Promise<AxiosResponse> {
        try {
            return axios.post(serviceUrl, postData, config);
        } catch (e) {
            this.handleLogout((e as AxiosError));
            throw e;
        }
    }

    public static async get(serviceUrl: string, config?: any): Promise<AxiosResponse> {
        try {
            return await axios.get(serviceUrl, config);
        } catch (e) {
            this.handleLogout((e as AxiosError));
            throw e
        }
    }
    
    public static async patch(serviceUrl: string, patchData: any, config?: any): Promise<AxiosResponse> {
        try {
            return axios.patch(serviceUrl, patchData, config);
        } catch (e) {
            this.handleLogout((e as AxiosError));
            throw e;
        }
    }

    public static async delete(serviceUrl: string, deleteData: any): Promise<AxiosResponse> {
        try {
            return axios.delete(serviceUrl, deleteData);
        } catch (e) {
            this.handleLogout((e as AxiosError));
            throw e;
        }
    }
}