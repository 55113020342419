import './HomePage.scss'
import { useTranslation } from 'react-i18next';
import { Col, Typography, Layout, Button, Image, Row } from 'antd';
import Navbar from '../../components/Navbar/Navbar';
import Footer from '../../components/Footer/Footer';
import Images from '../../img/images';
import { Link } from 'react-router-dom';

const HomePage = () => {
    const { t } = useTranslation();
    const {Title} = Typography;
    const { Content } = Layout;
  return (
    <Layout className="homePageContainer">
        <Navbar/>
        <Content>
          <Row className="heroContainer">
            <Col span={10} className='heroText'>    
              <Title className='heroTitle textH1'>{t('HomePage.title')}</Title>
              <Title level={3} className='heroSubtitle mainText'>{t('HomePage.subtitle')}</Title>
              <Button className='heroBtn btn primaryBtnDark btnLarge largeLabel'>
                <Link to='/register'>
                  {t('HomePage.getStarted')}
                </Link>
              </Button>
            </Col>
            <Col span={10} className='heroImages'>
              <Image className='heroImg' src={Images.heroImg} alt='dogPicture' preview={false}/>
            </Col>
          </Row>
          <Row className="howItWorks flexCenter">
            <Title level={2} className='textH2'>{t('HomePage.howItWorks')}</Title>
            <Col className="step">
              <div className="stepImgContainer">
                <Image className='stepImg' src={Images.registerIcon} alt='dogPicture' preview={false}/>
                <Image className='stepGif' src={Images.registerGif} alt='dogPicture' preview={false}/>
              </div>
              <div className="stepText">
                <p className='textH5 stepTitle'>{t('HomePage.register')}</p>
                <p className='bodyText'>{t('HomePage.registerSub')}</p>
              </div>
            </Col>
            <Col className="step">
              <div className="stepImgContainer">
                <Image className='stepImg' src={Images.publishIcon} alt='dogPicture' preview={false}/>
                <Image className='stepGif' src={Images.publishGif} alt='dogPicture' preview={false}/>
              </div>
              <div className="stepText">
                <p className='textH5 stepTitle'>{t('HomePage.publish')}</p>
                <p className='bodyText'>{t('HomePage.publishSub')}</p>
              </div>
            </Col>
            <Col className="step">
              <div className="stepImgContainer">
                <Image className='stepImg' src={Images.shareIcon} alt='dogPicture' preview={false}/>
                <Image className='stepGif' src={Images.shareGif} alt='dogPicture' preview={false}/>
              </div>
              <div className="stepText">
                <p className='textH5 stepTitle'>{t('HomePage.share')}</p>
                <p className='bodyText'>{t('HomePage.shareSub')}</p>
              </div>
            </Col>
          </Row>
          <Row className="cta flexCenter">
            <Title level={2} className='textH2 ctaTitle'>{t('HomePage.ctaText')}</Title>
            <Button className='btn btnLarge secondBtnLight largeLabel'>
              <Link to='/login'>
                {t('HomePage.ctaBtn')}
              </Link>
            </Button>
          </Row>
        </Content>
        <Footer/>
    </Layout>
  )
}

export default HomePage