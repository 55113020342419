import React, { useEffect, useState } from 'react'
import './EditForm.scss'
import {  Button, DatePicker, Form, Input, Radio, Select, Typography, Divider } from 'antd'
import { useTranslation } from 'react-i18next';
import { BreedsInterface } from '../../services/lostDogs/BreedsInterface';
import { LostDogService } from '../../services/lostDogs/LostDogService';
import { getBreedOptionsByLanguage } from '../../utils/util';
import Maps from '../Maps/Maps';
import TextArea from 'antd/lib/input/TextArea';
import moment from 'moment';

const EditForm: React.FC<any> = (props : any) => {
  const { postData, closeEditModal, editPost } = props
  const { t } = useTranslation();
  const { Paragraph } = Typography;
  const [form] = Form.useForm();

  const [breedOptions, setBreedOptions] = useState<{label: string, value: string}[]>()
  const [editedData, setEditedData] = useState({});
  useEffect(() => {
    const getBreeds = async () => {
        const breeds : BreedsInterface[] = await LostDogService.getDogBreeds()
        setBreedOptions(getBreedOptionsByLanguage(window.navigator.language, breeds))
    }
    getBreeds()
  }, []);

  const handleMap = (lat : number, lng : number, add : string) => {
    const latitude = lat.toString();
    const longitude = lng.toString();
    const address = add;
    setEditedData(oldData => ({
        ...oldData,
        latitude: latitude,
        longitude : longitude,
        address : address,
    }));     
  }

  const disableFutureDays = (current : any) => {
      return current && current > moment().endOf('day')        
  }

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setEditedData(oldData => ({
        ...oldData,
        [name]: value
    }));
  }

  const handleSelect = (name: string, value: any) => {
    setEditedData(oldData => ({
        ...oldData,
        [name]: value
    }));
  }

  const handleRadio = (name: string, e : any) =>{
    const {value} = e.target;
      setEditedData(oldData => ({
          ...oldData,
          [name]: value
      }));
  }

    return (
    <Form className='editFormContainer' layout='horizontal' form={form} onFinish={()=>editPost(postData.id, editedData)}>
        <Form.Item name='dogName' label={t('PublishLostPage.dogName')} >
            <Input name='dogName' placeholder={postData.dogName} onChange={handleChange} ></Input>
        </Form.Item>
        <Form.Item name='breed' label={t('PublishLostPage.breed')}  >
            <Select
                showSearch
                options={breedOptions}
                placeholder={postData.breed}
                filterOption={(inputLabel, option) =>
                    option?.label.toUpperCase().indexOf(inputLabel.toUpperCase()) !== -1
                }
                onChange={(value) => handleSelect('breed', value)}
                >
            </Select>
        </Form.Item>
        <Form.Item name='gender' label={t('PublishLostPage.sex')}>
            <Radio.Group onChange={(value) => handleRadio('gender', value)}>
                <Radio value='Male'>{t('PublishLostPage.male')}</Radio>
                <Radio value='Female'>{t('PublishLostPage.female')}</Radio>
            </Radio.Group>
        </Form.Item>
        <Form.Item name='lostdate' label={t('PublishLostPage.lostDate')} >
            <DatePicker name='lostdate' placeholder={postData.date} disabledDate={current => disableFutureDays(current)} onChange={(value) => handleSelect('date', value)}/>
        </Form.Item>
        <Form.Item name='address'>
            <Maps handleMap={handleMap} placeholder={postData.address}/>          
        </Form.Item>
        <Form.Item name='phoneNumber' label={t('PublishLostPage.phoneNumber')}>
            <Input name='phoneNumber' placeholder={postData.phoneNumber} onChange={handleChange}></Input>
        </Form.Item>
        <Form.Item name='description' label={t('ProfilePage.dogDescription')}>
            <TextArea showCount name='description' placeholder={postData.description} maxLength={300} onChange={handleChange} />
        </Form.Item>
        <Form.Item name='dogSize'>
            <>
                <p>{t('PublishLostPage.dogSize')}</p>
                <Radio.Group onChange={(value) => handleRadio('dogSize', value)}>
                    <Radio value='VerySmall'>{t('PublishLostPage.verySmall')}</Radio>
                    <Radio value='Small'>{t('PublishLostPage.small')}</Radio>
                    <Radio value='Medium'>{t('PublishLostPage.medium')}</Radio>
                    <Radio value='Large'>{t('PublishLostPage.large')}</Radio>
                </Radio.Group>
            </>
        </Form.Item>
        <Form.Item>
            <Divider/>
            <Paragraph>Desea guardar los cambios?</Paragraph>
            <div className='btnContainer'>
                <Button className='btn btnMedium secondBtnLight editBtn' type='primary' 
                onClick={()=>{closeEditModal(); form.resetFields()}}
                >{t('ProfilePage.cancel')}</Button>
                <Button className='btn btnMedium secondBtnDark editBtn' type='primary' htmlType='submit'>{t('ProfilePage.editSave')}</Button>
          </div>
        </Form.Item>
    </Form>
  )
}

export default EditForm